@font-face {
  font-family: 'MyHelvetica';
  src: local('Helvetica Neue Regular'), local('Helvetica-Neue-Regular'),
      url('./fonts/HelveticaNeueCyr-Regular.woff2') format('woff2'),
      url('./fonts/HelveticaNeueCyr-Regular.woff') format('woff'),
      url('./fonts/HelveticaNeueCyr-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MyHelvetica';
  src: local('Helvetica Neue Medium'), local('Helvetica-Neue-Medium'),
      url('./fonts/HelveticaNeueCyr-Medium.woff2') format('woff2'),
      url('./fonts/HelveticaNeueCyr-Medium.woff') format('woff'),
      url('./fonts/HelveticaNeueCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MyHelvetica';
  src: local('Helvetica Neue Bold'), local('Helvetica-Neue-Bold'),
      url('./fonts/HelveticaNeueCyr-Bold.woff2') format('woff2'),
      url('./fonts/HelveticaNeueCyr-Bold.woff') format('woff'),
      url('./fonts/HelveticaNeueCyr-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400&family=Passion+One:wght@700&family=Source+Sans+3:wght@300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'MyHelvetica';
  }
}

* {
  scroll-behavior: smooth;
}

input, textarea {
  background-color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow:  0 0 0px 1000px white inset;
  -webkit-text-fill-color: #212B36 !important;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

html {
  background: white !important;
}

body {
  position: relative;
  background: white;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}