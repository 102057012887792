.tooltip-triangle,
.tooltip-triangle::after,
.tooltip-triangle::before {
  height: 4px;
  width: 0.417vw;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
}

.tooltip-triangle {
  position: absolute;
  filter: drop-shadow(0px 6px 15px rgba(91, 90, 90, 0.2)) drop-shadow(0px 0px 2px rgba(91, 90, 90, 0.32));
  z-index: 1;
}

.tooltip-triangle::after,
.tooltip-triangle::before {
  content: '';
  position: absolute;
  top: -0.175vw;
}

.tooltip-triangle::before {
  left: -0.10008vw;
  transform: rotate(-60deg);
}

.tooltip-triangle::after {
  right: -0.10008vw;
  transform: rotate(60deg);
}