.dietary {
  & > path {
    fill: #4285F4
  }
}

.halal {
  & > path {
    fill: #A5842B
  }
}

.hot {
  & > path {
    fill: #E04141
  }
}

.kosher {
  & > path {
    fill: #2BA4D8
  }
}

.lenten {
  & > path {
    fill: #9CABBA
  }
}

.vegan {
  & * path {
    fill: #32936F
  }
}

.vegetarian {
  & * path {
    fill: #518B54
  }
}